<template>
    <navbar />
    <section class="results collected">
        <h1>Спасибо за игру!</h1>
        <p>
            Вы уже получили свои Т-коины ранее. Воспользуйтесь ими при заказе в Тюбетей
        </p>
        <img
            class="collected__zilant"
            src="../assets/img/zilant.svg"
            alt="Зилант"
        />
        <div class="buttons">
            <router-link
                to="/game"
                class="button"
            >
                Играть снова
                <svg width="30" height="28" viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 4.46722L3.91072 10.1015M3.91072 10.1015L11.1818 8.80125M3.91072 10.1015C5.24242 5.33403 9.8776 1.51282 14.8703 1.054C21.9966 0.405522 28.3 5.67284 28.9462 12.8244C29.5924 19.976 24.3436 26.3016 17.2173 26.9501C10.091 27.5986 5.24242 21.8033 3.54545 16.6025" stroke="#1C5444" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </router-link>
        </div>
    </section>
</template>
<script>
import Navbar from "@/components/Navbar.vue";

export default {
    name: 'RewardCollected',

    components: {
        Navbar
    }
}
</script>