<template>
    <navbar />
    <section class="start">
        <router-link to="/rules"
            class="button"
            href="https://arteildev.github.io/tubatay-game/guide.html"
        >
            Начать игру
            <svg
                width="28"
                height="30"
                viewBox="0 0 28 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M24 9.80385C28 12.1132 28 17.8868 24 20.1962L10.5 27.9904C6.5 30.2998 1.5 27.413 1.5 22.7942L1.5 7.20577C1.5 2.58697 6.5 -0.299783 10.5 2.00962L24 9.80385Z"
                    stroke="#1C5444"
                    stroke-width="2"
                />
            </svg>
        </router-link>
        <img
            class="start__zilant"
            src="../assets/img/zilant.svg"
            alt="Зилант"
        />
    </section>
    <div class="circle">    </div>
</template>
<script>
import Navbar from "@/components/Navbar.vue";

export default {
    name: "StartView",

    components: {
        Navbar
    },

    mounted() {
        if(this.$route.query.session) {
            localStorage.setItem('session', this.$route.query.session)
        }
        window.Telegram.WebApp.expand()
    }
};
</script>
