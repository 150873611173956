<template>
    <div class="score-bar">
        <span>00:{{ timer }}</span>
        <div class="score-bar__score">
            <span id="score">0</span>
            <img src="../assets/img/score_img.svg" alt="">
        </div>
    </div>
</template>
<script>
export default {
    name: 'ScoreBar',

    props: {
        timer: {
            default: '00'
        },
    },
}
</script>