<template>
    <navbar :timer="timer"/>
    <canvas id="canvas"></canvas>
</template>
<script>
import Navbar from "@/components/Navbar.vue";

export default {
    data() {
        return {
            score: 0,
            timer: 50,
            countDown: null
        }
    },

    components: {
        Navbar
    },

    methods: {
        Game() {
            let c = document.querySelector("canvas")
            let canvas = document.querySelector("canvas")
            c.width = innerWidth;
            c.height = innerHeight - 120;
            c = c.getContext("2d");

            this.startGame(c);
        },

        startGame(c) {
            let mouse = {
                x: innerWidth / 1.4,
                y: innerHeight - 150
            };

            canvas.addEventListener("mousemove", function (event) {
                mouse.x = event.clientX;
            });

            canvas.addEventListener("touchmove", function (event) {
                let touch = event.changedTouches[0];
                let touchX = parseInt(touch.clientX);
                event.preventDefault();
                mouse.x = touchX  * 1.4;
            });

            let score = 0
            let score_el = document.getElementById('score')

            let player_width = 156;
            let player_height = 90;
            let playerImg = new Image();
            playerImg.src = "https://arteildev.github.io/game/img/player.svg";
            let playerHand = new Image()
            playerHand.src = "https://arteildev.github.io/game/img/player_hand.svg";

            let _foods = [];
            let food_width = 64;
            let food_height = 64;

            function Player(x, y, width, height) {
                this.x = x;
                this.y = y;
                this.width = width;
                this.height = height;

                this.draw = function () {
                    c.beginPath();
                    c.drawImage(playerImg, mouse.x - player_width, mouse.y - player_height);
                    c.drawImage(playerHand, mouse.x - player_width-30, mouse.y - player_height+60);
                    this.x = mouse.x - player_width

                    // c.strokeStyle = '#FF0000'; // Цвет обводки
                    // c.lineWidth = 3; // Ширина линии
                    // c.fillStyle = '#00FF00'; // Цвет заливки

                    // c.strokeRect(mouse.x - player_width, mouse.y - player_height, 156, 90);
                };

                this.update = function () {
                    this.draw();
                };
            }

            function Food(x, y, width, height, speed, enemy) {
                this.x = x;
                this.y = y;
                this.width = width;
                this.height = height;
                this.speed = speed;
                this.enemy = enemy

                let foodImg = new Image();

                let foodimages = [
                    "https://arteildev.github.io/game/img/food-1.png",
                    "https://arteildev.github.io/game/img/food-2.png",
                    "https://arteildev.github.io/game/img/food-3.png",
                    "https://arteildev.github.io/game/img/food-4.png",
                    "https://arteildev.github.io/game/img/food-5.png",
                    "https://arteildev.github.io/game/img/food-6.png"
                ]

                let enemyImages = [
                    "https://arteildev.github.io/game/img/enemy-1.png",
                    "https://arteildev.github.io/game/img/enemy-2.png",
                    "https://arteildev.github.io/game/img/enemy-3.png",
                    "https://arteildev.github.io/game/img/enemy-4.png",
                    "https://arteildev.github.io/game/img/enemy-5.png"
                ]

                if(!enemy) {
                    foodImg.src = foodimages[Math.floor(Math.random() * foodimages.length)]
                } else {
                    foodImg.src = enemyImages[Math.floor(Math.random() * enemyImages.length)]
                }

                this.draw = function () {
                    c.beginPath();
                    c.drawImage(foodImg, this.x, this.y);
                };

                this.update = function () {
                    this.y += this.speed;
                    this.draw();
                };
            }

            let __player = new Player(mouse.x, mouse.y, player_width, player_height);
            
            let speed = 4
            let spawn_interval = 1000

            function drawFoods() {
                for (let _ = 0; _ < 1; _++) {
                    let x = Math.random() * (innerWidth - food_width);
                    let y = -food_height;
                    let width = food_width;
                    let height = food_height;
                    let enemy = Math.random() < .3;
                    speed += 0.1
                    if(spawn_interval > 100) {
                        spawn_interval -= 100
                    }
                    let __food = new Food(x, y, width, height, speed, enemy);
                    _foods.push(__food);
                }
            } setInterval(drawFoods, spawn_interval);

            function collision(a, b) {
                return a.x < b.x + b.width &&
                    a.x + a.width > b.x &&
                    a.y < b.y + b.height &&
                    a.y + a.height > b.y-90;
            }

            function animate() {
                requestAnimationFrame(animate);
                c.beginPath();
                c.clearRect(0, 0, innerWidth, innerHeight);
                c.fillStyle = 'black';

                __player.update();

                for (let k = 0; k < _foods.length; k++) {
                    _foods[k].update();
                }

                for (let j = _foods.length - 1; j >= 0; j--) {
                    if (collision(_foods[j], __player)) {
                        if(!_foods[j].enemy) {
                            if(score < 140) {
                                score += 3;
                                score_el.innerText = score
                                localStorage.setItem('score', score)
                            }
                        } else {
                            if(score > 0) {
                                score -= 3;
                                score_el.innerText = score
                                localStorage.setItem('score', score)
                            }
                        }
                        _foods.splice(j, 1);
                    }
                }
            }

            animate()
        },

        countDownTimer() {
            this.countDown = setInterval(() => {
                this.timer -= 1
                            
                if(this.timer === 0) {
                    const intervalId = window.setInterval(() => {}, Number.MAX_SAFE_INTEGER);

                    for (let i = 1; i < intervalId; i++) {
                        window.clearInterval(i);
                    }

                    this.$router.push('/results')
                }
            }, 1000)
        },
    },

    mounted() {
        localStorage.setItem('score', 0)
        this.Game()
        this.countDownTimer()
    },
}
</script>
