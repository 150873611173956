<template>
    <div
        class="navbar"
        :class="$route.name == 'start' ? '' : 'main'"
    >
        <img src="../assets/img/logo.svg" alt="Тюбетей">
        <span
            v-if="$route.name !== 'start'"
            @click="endGame()"
        >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="white"/>
                <path d="M11.2561 13.656V13.016C11.2561 12.5147 11.3468 12.0507 11.5281 11.624C11.7201 11.1973 12.0401 10.7333 12.4881 10.232C12.8295 9.85867 13.0641 9.53867 13.1921 9.272C13.3308 9.00533 13.4001 8.72267 13.4001 8.424C13.4001 8.072 13.2721 7.79467 13.0161 7.592C12.7601 7.38933 12.3975 7.288 11.9281 7.288C10.8828 7.288 9.91746 7.672 9.03213 8.44L8.36013 6.904C8.80813 6.49867 9.36279 6.17333 10.0241 5.928C10.6961 5.68267 11.3735 5.56 12.0561 5.56C12.7281 5.56 13.3148 5.672 13.8161 5.896C14.3281 6.12 14.7228 6.42933 15.0001 6.824C15.2881 7.21867 15.4321 7.68267 15.4321 8.216C15.4321 8.70667 15.3201 9.16533 15.0961 9.592C14.8828 10.0187 14.4988 10.4827 13.9441 10.984C13.4428 11.4427 13.0961 11.8427 12.9041 12.184C12.7121 12.5253 12.5895 12.8667 12.5361 13.208L12.4721 13.656H11.2561ZM10.7441 17V14.776H12.9841V17H10.7441Z" fill="white"/>
            </svg>
        </span>
    </div>
    <score-bar
        v-if="$route.name !== 'start'"
        :timer="timer"
    />
</template>
<script>
import ScoreBar from '@/components/ScoreBar.vue'

export default {
    name: "Navbar",

    props: {
        timer: {
            default: '00'
        },
    },

    components: {
        ScoreBar
    },

    methods: {
        endGame() {
            const intervalId = window.setInterval(() => {}, Number.MAX_SAFE_INTEGER);

            for (let i = 1; i < intervalId; i++) {
                window.clearInterval(i);
            }

            this.$router.push('/rules')
        }
    }
}
</script>