import { createRouter, createWebHistory } from 'vue-router'
import StartView from '../views/StartView.vue'
import RulesView from '../views/RulesView.vue'
import GameView from '../views/GameView.vue'
import ResultsView from '../views/ResultsView.vue'
import RewardCollected from '../views/RewardCollected.vue'

const routes = [
  {
    path: '/',
    name: 'start',
    component: StartView
  },
  {
    path: '/rules',
    name: 'rules',
    component: RulesView
  },
  {
    path: '/game',
    name: 'game',
    component: GameView
  },
  {
    path: '/results',
    name: 'results',
    component: ResultsView
  },
  {
    path: '/collected',
    name: 'collected',
    component: RewardCollected
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
