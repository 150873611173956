<template>
    <navbar />
    <section class="rules">
        <div class="rules-container">
            <router-link to="/game" class="rules-container__close">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L13 13M13 1L1 13" stroke="#1C5444" stroke-width="2" stroke-linecap="round"/>
                </svg>
            </router-link>
            <div class="rules-container__title">
                Как играть?
            </div>
            <div class="rules-container-list">
                <span>
                    <svg width="33" height="30" viewBox="0 0 33 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.59734 2.7608C-6.55822 17.9508 8.42264 28.2429 15.6877 28.8963C30.2179 30.2031 33.5202 18.8777 31.4287 10.6014C28.6034 -0.578748 9.22987 -4.20869 5.59734 11.9082" stroke="#B4EDBF" stroke-width="2" stroke-linecap="round"/>
                    </svg>
                    <p>Ваша задача - поймать лепешкой только съедобные ингредиенты, несъедобные нужно пропускать</p>
                </span>
                <span>
                    <svg width="33" height="30" viewBox="0 0 33 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.59734 2.7608C-6.55822 17.9508 8.42264 28.2429 15.6877 28.8963C30.2179 30.2031 33.5202 18.8777 31.4287 10.6014C28.6034 -0.578748 9.22987 -4.20869 5.59734 11.9082" stroke="#B4EDBF" stroke-width="2" stroke-linecap="round"/>
                    </svg>
                    <p>За каждый верный ингредиент вы получаете баллы, за неверный теряете</p>
                </span>
                <span>
                    <svg width="33" height="30" viewBox="0 0 33 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.59734 2.7608C-6.55822 17.9508 8.42264 28.2429 15.6877 28.8963C30.2179 30.2031 33.5202 18.8777 31.4287 10.6014C28.6034 -0.578748 9.22987 -4.20869 5.59734 11.9082" stroke="#B4EDBF" stroke-width="2" stroke-linecap="round"/>
                    </svg>
                    <p>У вас есть 50 секунд, за которые можно заработать до 140 баллов</p>
                </span>
                <span>
                    <svg width="33" height="30" viewBox="0 0 33 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.59734 2.7608C-6.55822 17.9508 8.42264 28.2429 15.6877 28.8963C30.2179 30.2031 33.5202 18.8777 31.4287 10.6014C28.6034 -0.578748 9.22987 -4.20869 5.59734 11.9082" stroke="#B4EDBF" stroke-width="2" stroke-linecap="round"/>
                    </svg>
                    <p>Вы всегда можете сыграть ещё раз и попытаться увеличить выигрыш</p>
                </span>
            </div>
        </div>
        <div class="rules-control" @click="$router.push('/game')">
            <img class="rules-control__control" src="../assets/img/control.svg" alt="">
            <img class="rules-control__player" src="../assets/img/player.svg" alt="">
        </div>
    </section>
</template>
<script>
import Navbar from "@/components/Navbar.vue";

export default {
    name: "RulesView",

    components: {
        Navbar
    }
};
</script>