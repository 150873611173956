<template>
    <navbar />
    <section class="results">
        <h1>Вы заработали <br> {{ score }} Т-коина!</h1>
        <p>
            Заберите их и обменяйте <br> на продукты в ресторанах <br> Тюбетей
        </p>
        <img
            class="results__zilant"
            src="../assets/img/zilant.svg"
            alt="Зилант"
        />
        <div class="buttons">
            <div
                v-if="session"
                class="button"
                @click="collectBonuses"
            >
                Забрать Т-коины
                <svg width="18" height="28" viewBox="0 0 18 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L16 14.4194L1 27" stroke="#1C5444" stroke-width="2" stroke-linecap="round"/>
                </svg>
            </div>
            <router-link
                to="/game"
                class="button"
            >
                Играть снова
                <svg width="30" height="28" viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 4.46722L3.91072 10.1015M3.91072 10.1015L11.1818 8.80125M3.91072 10.1015C5.24242 5.33403 9.8776 1.51282 14.8703 1.054C21.9966 0.405522 28.3 5.67284 28.9462 12.8244C29.5924 19.976 24.3436 26.3016 17.2173 26.9501C10.091 27.5986 5.24242 21.8033 3.54545 16.6025" stroke="#1C5444" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </router-link>
        </div>
        <div class="circle"></div>
    </section>
</template>
<script>
import axios from 'axios'
import Navbar from "@/components/Navbar.vue";

export default {
    name: 'ResultsView',

    data() {
        return {
            score: 0,
            session: null,
            queryId: null
        }
    },

    components: {
        Navbar
    },

    methods: {
        checkScore() {
            this.score = localStorage.getItem('score')
            if(this.score) {
                document.getElementById('score').innerText = this.score
            } else {
                document.getElementById('score').innerText = 0
            }
        },

        collectBonuses() {
            if(this.session) {
                axios
                .get("https://tcode.online/api/game-sessions/"+ this.session +"/")
                .then((response) => {
                    response.data.is_finished

                    if(!response.data.is_finished) {
                        axios
                        .put("https://tcode.online/api/game-sessions/"+ this.session +"/", {
                            is_finished: true,
                            score: this.score
                        })
                        .then((response) => {
                            if(response.status === 200) {
                                window.Telegram.WebApp.close()
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    } else {
                        this.$router.push('/collected')
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
            }
        }
    },

    mounted() {
        this.checkScore()
        this.session = JSON.parse(localStorage.getItem('session')) ?? null
    }
}
</script>